<template>
  <div class="bruch content">

    <div class="lores s12">
      <p class="title lores s21">
        &gt; Brüch
      </p>

      <span class="title lores s12 flipped secondary" id="albums">
        ALBUMS & EPs
      </span>

      <div class="lores s12 release">
        <div class="lores s21">Apart (12'29")</div>
        <div class="links">
          <div>20 of SEP '24</div>
          <a class="cs tertiary" href="#creators">Creator&nbsp;Safe</a>
          <br>
          <a href="https://open.spotify.com/album/3vgehN7mEN8JoE6ZHEEycq" target="_blank">Spotify</a>
          <a href="https://music.apple.com/au/album/apart-ep/1763053836" target="_blank">Apple Music</a>
        </div>
      </div>

      <div class="lores s12 release">
        <div class="lores s21">Fable (21'38")</div>
        <div class="links">
          <div>26 of FEB '21</div>
          <a class="cs tertiary" href="#creators">Creator&nbsp;Safe</a>
          <br>
          <a href="https://open.spotify.com/album/2978BucxMIwNLfHeKEW7r8" target="_blank">Spotify</a>
          <a href="https://music.apple.com/us/album/fable/1551504835" target="_blank">Apple Music</a>
        </div>
      </div>

      <br><br>

      <span class="title lores s12 flipped secondary" id="singles">
        SINGLES
      </span>

      <div class="lores s12 release">
        <div class="lores s21">Paroaria (4'12")</div>
        <div class="links">
          <div>23 of JUN '20</div>
          <a class="cs tertiary" href="#creators">Creator&nbsp;Safe</a>
          <br>
          <a href="https://open.spotify.com/album/7iGgYz8rlc2njjTaoIVUBu" target="_blank">Spotify</a>
          <a href="https://music.apple.com/us/album/paroaria-single/1513775674" target="_blank">Apple Music</a>
        </div>
      </div>

      <div class="lores s12 release">
        <div class="lores s21">Version 11 (4'17")</div>
        <div class="links">
          <div>17 of APR '20</div>
          <a class="cs tertiary" href="#creators">Creator&nbsp;Safe</a>
          <br>
          <a href="https://open.spotify.com/track/1cQhPt7FV4tkcVHiGCBuXE" target="_blank">Spotify</a>
          <a href="https://music.apple.com/us/album/version-11/1508779010" target="_blank">Apple Music</a>
        </div>
      </div>

      <br><br>

      <span class="title lores s12 flipped secondary" id="other">
        OTHER
      </span>

      <div class="lores s12">
        <div class="lores s21">CULT TAPE 001 — SELECTED NOISE WORKS</div>
        <div>My song 'The Bruises On My Skin' was featured on Kavaris 'CULT TAPE 001'</div>
        <div class="links">
          <a href="https://soundcloud.com/bruchumlaut/the-bruises-on-my-skin?in=kavarimusic/sets/cult-tape-001-selected-noise"
            target="_blank">
            The Bruises On My Skin
          </a>
          <a href="https://soundcloud.com/kavarimusic/sets/cult-tape-001-selected-noise" target="_blank">
            SoundCloud
          </a>
          <a href="https://kavarimusic.bandcamp.com/album/cult-tape-001-selected-noise-works" target="_blank">
            Bandcamp
          </a>
        </div>
      </div>

      <div class="lores s12">
        <div class="lores s21">... Playlists</div>
        <div class="links">
          <a href="https://open.spotify.com/playlist/1TbnB3cShUUAkB5lE0oUaA?si=defc2637d6534365" target="_blank">LAB
            &mdash; Brüch</a>
          <a href="https://open.spotify.com/playlist/4cmaCb7HYKFoodj1X3DZCr?si=d4941c7b8b9d4bef" target="_blank">Lounge
            -ish</a>
        </div>
      </div>


      <br>
      <br>
      <div class="lores s12" id="creators">
        <div class="lores s21">&gt; Creator Safe</div>
        <p class="nottoolong">
          All independant releases from Brüch (make sure the individual release is released under the record label
          'Brüch', or labeled 'Creator Safe' in the list above, otherwise this is not guaranteed) are free to use for
          YouTubers, Twitch Streamers, and other forms of independant online content creation as long as it follows <a
            href="#1">[1]</a> & <a href="#2">[2]</a>.
          <br>
          <br>
          When using this music, please credit when possible. Something like this:<br>
          <b class="bold">Song:</b> Collapse<br>
          <b class="bold">Artist:</b> Brüch<br>
          Courtesy of Brüch &mdash; <a href="https://liara.io/bruch">https://liara.io/bruch</a>
          <br>
          <br>
          <span class="bold" id="1">[1]</span><br>
          TL;DR: public YouTube videos, Podcasts, and Twitch streams are a-okay!<br>
          No commercial projects except for passive ad revenue generation, subscriptions, sponsorships, &amp; donations on
          live streams, podcasts, &amp; VOD content.<br>
          The content must be publically accesible and not be gated behind a paywall.<br>
          Early Access paywalls (such as content released on Patreon before a later public release) are acceptable as long
          as the content becomes accesible to the public with an announced release date stated at the time of early access
          release.<br>
          For commercial use contact me.
          <br>
          <br>
          <span class="bold" id="2">[2]</span><br>
          No projects promoting, generating revenue from, or wherein a participant is recieving payment from an entity
          involved with any of the following:<br>
          Gambling, Cryptocurrency, NFTs.
        </p>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'Bruch'
}
</script>

<style lang="scss" scoped>
.links div {
  display: inline-block;
}

.links div:not(:last-child),
.links a:not(:last-child) {
  margin-right: 2rem;
}
</style>
